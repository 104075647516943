<template>
  <div
    class="psui-el-radio"
    :class="[getComponentClass, { disabled: disabled }]"
  >
    <input
      :id="inputId"
      type="radio"
      :name="inputId"
      :value="inputValue"
      v-model="model"
      :disabled="disabled"
      :checked="isChecked"
    >
    <label
      :for="inputId"
      class="psui-el-checkmark"
    ><span>{{ label }}</span></label>
  </div>
</template>

<script setup>
//FIGMA CONTROLS & SELECTORS https://www.figma.com/file/Tto8hrNlSfuPcwd1pfqogF/%E2%9A%A1%EF%B8%8F-Design-System?node-id=1768%3A64852
import { computed } from 'vue'

const props = defineProps({
  inputId: {
    type: String,
    default: '',
  },
  /**
   * It sets the label of the radio button.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * It's the value of the checkbox
   */
  inputValue: {
    type: [String, Object],
    default: '',
  },
  value: {
    type: [String, Object],
    default: '',
  },
  /**
   * It disables the radio button. All mouse events are disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the size of the radio button. eg: big and small.
   */
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['big', 'small'].includes(value),
  },
})

const emit = defineEmits(['input'])
const getComponentClass = computed(() => {
  return `size-${props.size}`
})

const model = computed({
  get: () => {
    return props.value
  },
  set: (value) => {
    emit('input', value)
  },
})

const isChecked = computed(() => {
  return props.inputValue === props.value
})
</script>
