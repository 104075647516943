<template>
  <div
    v-if="withPrevent"
    class="psui-el-checkbox"
    :class="[getComponentClass, { disabled: disabled }]"
    v-bind="getComponentAttrs"
    @click.prevent="emit('click', $event)"
  >
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      :name="label"
      :id="getInputId"
      v-bind="inputAttrs"
      @change="emit('change', $event)"
    >
    <label
      :for="getInputId"
      class="psui-el-checkmark"
    >
      <slot name="content">
        <span>{{ label }}</span>
      </slot>
    </label>
  </div>
  <div
    v-else
    class="psui-el-checkbox"
    :class="[getComponentClass, { disabled: disabled }]"
    v-bind="getComponentAttrs"
  >
    <input
      type="checkbox"
      :checked="checked"
      :disabled="disabled"
      :name="label"
      :id="getInputId"
      v-bind="inputAttrs"
      @change="emit('change', $event)"
    >
    <label
      :for="getInputId"
      class="psui-el-checkmark"
      :title="showLabelTitle ? label : ''"
    >
      <slot name="content">
        <span>{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script setup>
//FIGMA CONTROLS & SELECTORS https://www.figma.com/file/Tto8hrNlSfuPcwd1pfqogF/%E2%9A%A1%EF%B8%8F-Design-System?node-id=1768%3A64852
import { randomString } from '../../util/GeneralFunctions.js'
import { computed, useAttrs } from 'vue'

const emit = defineEmits(['change', 'click'])

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  /**
   * It disables the checkbox input.
   */
  withPrevent: {
    type: Boolean,
    default: false,
  },
  
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the input as checked
   */
  checked: {
    type: Boolean,
    default: false,
  },
  /**
   * Add html attributes to the element input
   */
  inputAttrs: {
    type: Object,
    default: () => ({}),
  },
  /**
   * It set the of the checkbox input. eg: big and small.
   */
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['big', 'small'].includes(value),
  },
  /**
   * It set the layout of the checkbox input. eg: default and mixed.
   */
  layout: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'mixed'].includes(value),
  },
  showLabelTitle: {
    type: Boolean,
    default: true,
  },
})
const attrs = useAttrs()

const getComponentClass = computed(() => {
  return `size-${props.size} layout-${props.layout}`
})


const getInputId = computed(() => {
  if (props?.inputAttrs?.id) {
    return props.inputAttrs.id
  } else if (attrs?.id) {
    return attrs.id
  } else {
    return randomString(16)
  }
})

const getComponentAttrs = computed(() => {
  let componentAttrs = { ...attrs }
  delete componentAttrs.id
  return componentAttrs
})
</script>
