<template>
  <div
    class="psui-el-accordion-item"
    :class="{ 'status-opened': isOpen, disabled: disabled }"
  >
    <div class="psui-el-accordion-item-header">
      <slot
        name="custom-header"
        :toggle="toggle"
        :is-open="isOpen"
      >
        <div
          @click="toggle"
          class="psui-el-accordion-item-header-wrapper"
          v-if="!hasCustomHeader"
        >
          <h2
            v-if="title"
            class="psui-el-accordion-item-title"
          >
            {{ title }}
          </h2>
          <slot name="header-additionals" />
          <i
            v-if="!disabled"
            class="psui-el-accordion-item-icon"
          >{{ getIcon }}</i>
        </div>
      </slot>
    </div>
    <transition
      name="accordion-fade"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-if="isOpen"
        class="psui-el-accordion-item-content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { getParentVueComponentByName } from '../../util/GeneralFunctions.js'
import { ref, computed, getCurrentInstance  } from 'vue'

const localOpened = ref(null)

const props = defineProps({
  /**
   * It sets the title of the accordion item.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * It sets the status of the accordion item when mounted.
   */
  opened: {
    type: [Boolean],
    default: false,
  },
  /**
   * It sets the text key to get the svg icon in Google Fonts. Make sure to get the correct description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: '',
  },
  /**
   * It sets if it has a custom header.
   */
  hasCustomHeader: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the disabled status.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
})

const isOpen = computed(() => {
  return localOpened.value !== null ? localOpened.value : props.opened
})

const getParent = computed(() => {
  const instance = getCurrentInstance()

  if (instance?.parent) {
    return getParentVueComponentByName(instance.parent, 'PsAccordion')
  }

  if (instance.proxy && instance.proxy.$parent) {
    return getParentVueComponentByName(instance.proxy.$parent, 'PsAccordion')
  }

  return null
})

const getIcon = computed(() => {
  if (!getParent.value) return

  const layout = getParent.value._props ? getParent.value._props.layout : getParent?.value?.props.layout.default
  if (layout === 'medium') return 'expand_more'
  if (layout === 'big' && isOpen.value === false) return 'add'
  if (layout === 'big' && isOpen.value === true) return 'remove'
  return `${props.icon}`
})

const toggle = () => {
  if (localOpened.value === null) {
    localOpened.value = !props.opened
  } else {
    localOpened.value = !localOpened.value
  }
}
const start = (el) => {
  el.style.height = el.scrollHeight + 'px'
  el.style.padding = el.srcollPaddingBottom + 'px'
}
const end = (el) => {
  el.style.height = ''
  el.style.padding = ''
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsAccordion.scss */
</style>



