<template>
  <tbody>
    <tr
      v-for="(row, index) in getRows"
      :key="index"
    >
      <td>
        <p class="title">
          {{ row.title }}
        </p>
      </td>

      <template v-for="(columnGroup, indexColumn) of columnGroups">
        <td
          v-for="column of columnGroup.columns"
          :key="indexColumn + '-' + columnGroup.key + '-' + column.key"
        >
          <p>{{ column.title }}</p>
        </td>
      </template>
    </tr>
  </tbody>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  /**
   * It sets the values which will be use to render the body.
   */
  summaryData: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the values which will be use to render the body.
   */
  columnGroups: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the values which will be use to render the body.
   */
  rows: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets additional styling if needed.
   */
  cssStyle: {
    type: String,
    default: 'psui-text-gray-60',
  },
})

const cssAlign = computed(() => {
  if (props.align === 'right') return `psui-text-right ${props.cssStyle}`
  if (props.align === 'center') return `psui-text-center  ${props.cssStyle}`
  return `psui-text-left ${props.cssStyle}`
})

const addRow = (item, deep = 0, index, rows) => {
  item.deep = deep
  item.index = index
  rows.push(item)
  item.last_deep = item.items ? false : true
  if (item.items) {
    const items_child = [...item.items]
    for (let indexChild = 0; indexChild < items_child.length; indexChild++) {
      const item_child = items_child[indexChild]
      item_child.is_last = indexChild + 1 === items_child.length ? true : false
      addRow(item_child, deep + 1, `${index}-${indexChild}`, rows)
    }
  }
}

const getRows = computed(() => {
  const rows = []
  for (let index = 0; index < props.summaryData.length; index++) {
    const item = props.summaryData[index]
    addRow(item, 0, `${index}`, rows)
  }
  return rows
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsTableResults.scss */
</style>
