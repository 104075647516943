<template>
  <div
    class="psui-el-input"
    :class="[getComponentClass, `layout-${layout}`]"
  >
    <label v-if="label"> {{ label }} </label>

    <div
      class="psui-el-input-wrapper"
      @mouseenter="emit('mouseenter', $event)"
      @mouseleave="emit('mouseleave', $event)"
    >
      <div
        v-if="$slots.prepend"
        class="psui-el-input-prepend"
      >
        <slot name="prepend" />
      </div>
      <PsRichTooltip
        class="psui-w-full"
        css-class="psui-w-48"
        :ignore-dialog="!showTooltip"
      >
        <template #trigger>
          <input
            :title="title"
            :type="type"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="value"
            :step="step"
            :min="minValue"
            :max="maxValue"
            @focus="onInputFocus"
            @blur="onInputBlur"
            @input="emit('input', $event)"
            @keydown="emit('keydown', $event)"
            @change="emit('change', $event)"
            v-bind="getAttrs"
            :class="{ focus: isFocus }"
          >
        </template>
        <template #content>
          <div class="text-left">
            {{ tooltipText }}
          </div>
        </template>
      </PsRichTooltip>
      <div
        v-if="$slots.append || hasError"
        class="psui-el-input-append"
      >
        <span
          v-if="hasError"
          class="material-icons psui-text-red-20 material-icons-sharp"
        >error</span>
        <slot
          name="append"
        />
      </div>
    </div>

    <p
      v-if="hint || (hasError && typeof hasError === 'string')"
      class="psui-el-input-hint"
    >
      {{ typeof hasError === 'string' ? hasError : hint }}
    </p>
  </div>
</template>

<script setup>
import PsRichTooltip from '../tooltip/PsRichTooltip.vue'
import { ref, computed, useAttrs } from 'vue'

const props = defineProps({
  /**
   * It set the title and also its tooltip default behavior
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * It sets the type of your button. default: text.
   */
  type: {
    type: String,
    default: 'text',
  },
  /**
   * It sets a placeholder for your button if needed.
   */
  placeholder: {
    type: String,
    default: '',
  },
  /**
   * It sets the label of the input if needed.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * It sets a text below the input. See Figma Text fileds & Forms.
   */
  hint: {
    type: String,
    default: '',
  },
  /**
   * It sets the layout of your button. eg: mini and default.
   */
  layout: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'mini'].includes(value),
  },
  /**
   * It sets the value of the button.
   */
  value: {
    required: true,
  },
  /**
   * It disables the input. All mouse events are disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets if the input found any error.
   */
  hasError: {
    type: [String, Boolean],
    default: false,
  },
  /**
   * It sets a active css class based on a boolean property.
   */
  active: {
    type: Boolean,
    default: false,
  },
  minValue: {
    type: null,
  },
  maxValue: {
    type: null,
  },
  step: {
    type: null,
  },
  showTooltip: {
    type: Boolean,
    default: false,
  },
  tooltipText: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['focus', 'blur', 'mouseenter', 'mouseleave', 'input', 'keydown', 'change'])

const isFocus = ref(false)
const attrs = useAttrs()

const getAttrs = computed(() => {
  const defaultAttrs = {
    autocapitalize: 'sentences',
    autocomplete: 'chrome-off',
    autocorrect: 'off',
    spellcheck: 'spellcheck',
  }
  return { ...defaultAttrs, ...attrs }
})

const getComponentClass = computed(() => {
  if (props.disabled) {
    return 'status-disabled'
  } else if (props.hasError) {
    return 'status-error'
  } else if (isFocus.value || props.active) {
    return 'status-active'
  } else {
    return 'status-resting'
  }
})

const onInputFocus = ($event) => {
  isFocus.value = true
  emit('focus', $event)
}

const onInputBlur = ($event) => {
  isFocus.value = false
  emit('blur', $event)
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsInput.scss */
</style>
