<template>
  <div
    class="psui-el-input-textarea"
    :class="[{ disabled: disabled }]"
  >
    <div class="psui-el-input-textarea-wrapper">
      <label
        :for="label"
        v-if="label"
      >{{ label }}</label>
      <textarea
        :id="label"
        :autocapitalize="autocapitalize"
        :aria-required="required"
        :value="value"
        @focus="onInputFocus"
        @blur="onInputBlur"
        @input="emit('input', $event)"
        @change="emit('change', $event)"
        :rows="rows"
        :placeholder="placeholder"
        :readonly="disabled"
      />
    </div>
    <p
      class="psui-el-input-textarea-message"
      v-if="optionalLabel && !disabled"
    >
      {{ optionalLabel }}
    </p>
  </div>
</template>

<script setup>

defineProps({
  /**
   * It sets the placeholder of the textarea input.
   */
  placeholder: {
    type: String,
    default: '',
  },
  /**
   * It sets the value selected of the textarea input.
   */
  value: {
    required: true,
  },
  /**
   * It sets the label of the textarea input.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * It sets a optional label if needed.
   */
  optionalLabel: {
    type: String,
    default: '',
  },
  /**
   * It sets aria-required property. eg: true or false.
   */
  required: {
    type: Boolean,
    default: true,
  },
  /**
   * It sets the type of autocapitalize. See mdn web-doc for full information. default: sentences.
   */
  autocapitalize: {
    type: String,
    default: 'sentences',
  },
  /**
   * It sets the size of the textarea input.
   */
  rows: {
    type: Number,
    default: 5,
  },
  /**
   * It disabled the textarea input. All mouse events are disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['focus', 'blur', 'input', 'change'])

const onInputFocus = ($event) => {
  emit('focus', $event)
}

const onInputBlur = ($event) => {
  emit('blur', $event)
}
</script>
