<template>
  <table
    :class="cssAlign"
    class="psui-el-table psui-table"
  >
    <thead v-if="header">
      <th
        v-for="head in header"
        :key="head"
      >
        {{ head }}
      </th>
    </thead>
    <tbody v-if="type === 'simple'">
      <tr
        v-for="(dt, index) in data"
        :key="index"
      >
        <td
          v-for="(head, cellRow) in header"
          :key="cellRow"
        >
          {{ data[index][head] }}
        </td>
      </tr>
    </tbody>
    <slot v-else />
    <tfoot v-if="footer">
      <th
        v-for="ft in footer"
        :key="ft"
        class="psui-bg-gray-10 psui-text-gray-80 psui-font-normal"
      >
        {{ ft }}
      </th>
    </tfoot>
  </table>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    required: true,
    validator: (value) => {
      return ['simple', 'rich'].indexOf(value) !== -1
    },
  },
  /**
   * It sets the values which will be use to render the header.
   */
  header: {
    type: Array,
    required: true,
  },
  /**
   * It sets the values which will be use to render the body.
   */
  data: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the values which will be use to render the footer.
   */
  footer: {
    type: Array,
    default: () => [],
  },
  /**
   * It sets the alignment of the whole context.
   */
  align: {
    type: String,
    default: 'left',
    validator: (value) => {
      return ['center', 'right', 'left'].indexOf(value) !== -1
    },
  },
  /**
   * It sets additional styling if needed.
   */
  cssStyle: {
    type: String,
    default: 'psui-text-gray-60',
  },
})

const cssAlign = computed(() => {
  if (props.align === 'right') return `psui-text-right ${props.cssStyle}`
  if (props.align === 'center') return `psui-text-center  ${props.cssStyle}`
  return `psui-text-left ${props.cssStyle}`
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsDataTable.scss */
</style>
