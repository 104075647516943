<template>
  <div
    class="psui-el-chips"
    :class="[getComponentClass, `layout-${layout}`, `type-${type}`]"
    v-tooltip="{
      content: disabledText,
      trigger: 'hover',
      placement: 'bottom',      
      show: layout === 'disabled' && disabledText !== '' && isHovering
    }"
    @mouseover="isHovering = true"
    @mouseleave="isHovering = false"
  >
    <input
      @change="onChange"
      :disabled="disabled"
      v-if="type != 'button'"
      :type="type"
      :id="getInputId"
      :checked="checked"
    >
    <label
      :disabled="disabled"
      :for="getInputId"
      :class="{'checked': checked && type === 'button', 'layout-disabled': layout === 'disabled'}"
    >
      <i
        v-if="icon"
        class="psui-el-chips-icon psui-el-chips-icon-prepend"
      >{{ icon }}</i>
      {{ label }}
      <button
        @click="onClose"
        v-if="layout === 'rich'"
        class="psui-el-chips-close"
      >
        <span class="psui-el-chips-icon">close</span>
      </button>
    </label>
  </div>
</template>

<script setup>
import { computed, useAttrs, ref } from 'vue'

import { randomString } from '../../util/GeneralFunctions.js'

const emit = defineEmits(['click', 'change', 'update:checked', 'close'])

const attrs = useAttrs()

const props = defineProps({
  /**
   * It sets the label of the chips button.
   */
  label: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
    default: 'button',
    validator: (type) => ['radio', 'checkbox', 'button'].includes(type),
  },
  /**
   * It sets the layout of the chipsbutton. eg: simple, with-icon, rich.
   */
  layout: {
    type: String,
    default: 'simple',
    validator: (value) => ['simple', 'with-icon', 'rich', 'disabled'].includes(value),
  },
  /**
   * It sets the text key which will retrieve a icon from Google Fonts. Make sure to get the right description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: '',
  },
  /**
   * It sets if the button was checked or not checked.
   */
  checked: {
    type: Boolean,
    default: false,
  },
  /**
   * It disables the chips button.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets if the button is active or not.
   */
  active: {
    type: Boolean,
    default: false,
  },
  /**
   * Add html attributes to the element input
   */
  inputAttrs: {
    type: Object,
    default: () => ({}),
  },
  disabledText: {
    type: String,
    default: '',
  }
})

const isHovering = ref(false)

const getComponentClass = computed(() => {
  if (props.disabled) {
    return 'status-disabled'
  } else {
    return 'status-resting'
  }
})

const getInputId = computed(() => {
  if (props?.inputAttrs?.id) {
    return props.inputAttrs.id
  } else if (attrs?.id) {
    return attrs.id
  } else {
    return randomString(16)
  }
})

const onChange = (event) => {
  emit('update:checked', event.target.checked)
  emit('change', event)
}

const onClose = () => {
  emit('close')
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsChips.scss */
</style>
