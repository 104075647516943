<template>
  <div
    class="psui-el-progress-bar"
    :style="{ width: `${width}px` }"
    :class="{ 'is-breakeven': getIsBreakEven }"
  >
    <div class="psui-el-progress-bar-bg" />
    <div
      class="psui-el-progress-bar-value"
      :class="[getBg, { 'rounded-tr-full rounded-br-full': getProgressWidth === '100%' }]"
      :style="{ width: getProgressWidth }"
    />
    <div
      class="psui-el-progress-bar-divider"
      :style="{ left: getBreakEvenLeft }"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  width: {
    type: [String, Number],
    default: 100,
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 5,
  },
  breakEven: {
    type: Number,
    default: 1,
  },
  forceBreakEven: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [String, Number],
    default: null,
  },
  positiveBg: {
    type: String,
    default: 'psui-bg-blue-60',
  },
  negativeBg: {
    type: String,
    default: 'psui-bg-red-20',
  },
})

const getVolume = computed(() => {
  return props.max - props.min
})

const getProgress = computed(() => {
  if (props.value == 9876543210) return 100
  const progress = (100 / getVolume.value) * props.value
  return progress > 100 ? 100 : progress
})

const getProgressWidth = computed(() => {
  return `${getProgress.value}%`
})

const getIsBreakEven = computed(() => {
  if (props.forceBreakEven) return true
  return props.value >= props.breakEven ? true : false
})

const getBreakEvenLeft = computed(() => {
  return `${(100 / getVolume.value) * props.breakEven}%`
})

const getBg = computed(() => {
  if (props.forceBreakEven) return props.positiveBg
  return getIsBreakEven.value ? props.positiveBg : props.negativeBg
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsProgressBar.scss */
</style>
