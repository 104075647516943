<template>
  <div
    class="psui-el-checkbox"
    :class="[getComponentClass, { disabled: disabled }]"
  >
    <input
      :id="getInputId"
      type="checkbox"
      :value="inputValue"
      v-model="model"
      :disabled="disabled"
    >
    <label
      :for="getInputId"
      class="psui-el-checkmark"
      :class="[labelClasses, 'psui-el-checkmark']"
    >
      <span>{{ label }}</span>
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { randomString } from '../../util/GeneralFunctions.js'

const props = defineProps({
  /**
   * It sets the label of the checkbox input.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * It sets the label classes.
   */
  labelClasses: {
    type: String,
    default: '',
  },
  /**
   * It sets the value which should be monitored.
   */
  inputValue: {
    type: [String, Boolean],
    default: '',
  },
  value: {
    type: [Array, Boolean],
    default: false,
  },
  /**
   * It disables the checkbox input.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It set the of the checkbox input. eg: big and small.
   */
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['big', 'small'].includes(value),
  },
  /**
   * It set the layout of the checkbox input. eg: default and mixed.
   */
  layout: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'mixed'].includes(value),
  },
})

const emit = defineEmits(['input'])

const getInputId = computed(() => {
  if (props.inputAttrs?.id) {
    return props.inputAttrs.id
  } else if (props.$attrs?.id) {
    return props.$attrs.id
  } else {
    return randomString(16)
  }
})

const getComponentClass = computed(() => `size-${props.size} layout-${props.layout}`)

const model = computed({
  get() {
    return props.value
  },
  set(newValue) {
    emit('input', newValue)
  },
})
</script>
