<template>
  <div
    ref="PsTooltip"
    class="psui-el-tooltip"
    @mouseenter="open"
    @mouseleave="close"
  >
    <div
      ref="PsTooltipTrigger"
      class="psui-el-tooltip-trigger"
    >
      <slot name="trigger" />
    </div>

    <div class="psui-el-tooltip-wrapper">
      <div
        role="menu"
        ref="PsTooltipDialog"
        class="psui-el-tooltip-dialog"
        :class="cssClass"
      >
        <div
          class="psui-el-tooltip-content"
          aria-orientation="vertical"
          :class="getComponentClass"
        >
          <h2 v-if="title">
            {{ title }}
          </h2>
          <div
            v-else
            class="psui-el-tooltip-content-wrapper"
          >
            <slot name="content" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  /**
   * It sets the title of the tooltip if needed.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * It disables the dialog section.
   */
  ignoreDialog: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the layout of the tooltip if needed. Usefull when using rich or dialog tooltip.
   */
  layout: {
    type: String,
    default: '',
  },
  /**
   * It sets a additional styling if needed.
   */
  cssClass: {
    type: String,
    default: '',
  },
  /**
   * It sets the vertical position.
   */
  position: {
    type: String,
    default: 'bottom',
    validator: (value) => ['bottom', 'top', 'custom'].includes(value),
  },
  /**
   * It sets the custom positions.
   */
  customPosition: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['show', 'close'])

const show = ref(false)
// const closingTimeout = ref(null)
const PsTooltip = ref(null)
const PsTooltipTrigger = ref(null)
const PsTooltipDialog = ref(null)

const getComponentClass = computed(() => {
  return `layout-${props.layout}`
})

onMounted(() => {
  document.addEventListener('resize', updatePosition)
})

onBeforeUnmount(() => {
  document.removeEventListener('resize', updatePosition)
})

const open = () => {
  if (show.value || props.ignoreDialog) return
  emit('show')
  show.value = true
  const dialog = PsTooltipDialog.value
  dialog.style.display = 'block'
  dialog.style.opacity = 0
  setTimeout(() => {
    updatePosition()
  }, 10)
}

const close = () => {
  const dialog = PsTooltipDialog.value
  if (show.value && dialog) {
    emit('close')
    show.value = false
    dialog.style.display = 'none'
  }
}

const updatePosition = () => {
  const dialog = PsTooltipDialog.value
  const trigger = PsTooltipTrigger.value
  const rectDialog = dialog?.getBoundingClientRect()
  const rectTrigger = trigger?.getBoundingClientRect()
  const windowWidth = document.documentElement.clientWidth
  if(dialog) dialog.style.top = `${rectTrigger.y + rectTrigger.height + 8}px`
  if (rectTrigger && rectTrigger.x + rectDialog.width + 20 > windowWidth) {
    dialog.style.left = `${windowWidth - rectDialog.width - 30}px`
  } else if (rectTrigger && rectTrigger.x - rectDialog.width < 0) {
    dialog.style.left = `${rectTrigger.x + 10}px`
  } else if (rectTrigger && rectDialog.width >= rectTrigger.width && props.position != 'custom') {
    dialog.style.left = `${rectTrigger.x - (rectDialog.width - rectTrigger.width) / 2}px`
  } else if(dialog) {
    dialog.style.left = `${rectTrigger.x + (rectTrigger.width - rectDialog.width) / 2}px`
  }
  if (props.position == 'top') {
    dialog.style.top = `${rectTrigger.y - rectDialog.height - 10}px`
  }
  if (props.position == 'custom') {
    dialog.style = props.customPosition
    dialog.style.display = 'block'
    dialog.style.position = 'absolute'
  }
  setTimeout(() => {
    if(dialog) dialog.style.opacity = 100
  }, 100)
}
</script>
