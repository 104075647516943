<template>
  <div
    class="psui-el-tab-header"
    :class="[getComponentClass, `layout-${layout}`]"
    role="group"
  >
    <template v-for="(item, index) in getItems">
      <slot :item="item">
        <PsRichTooltip
          v-if="item.tooltip"
          :title="item.tooltip"
          layout="blue"
        >
          <template #trigger>
            <button
              :key="item[keyValue] + index"
              type="button"
              @click="selectTab(item)"
              :class="getButtonClass(item).value"
            >
              <span>{{ item[keyLabel] }}</span>
            </button>
          </template>
        </PsRichTooltip>
        <button
          v-else
          :key="item[keyValue] + index"
          type="button"
          @click="selectTab(item)"
          :class="getButtonClass(item).value"
        >
          <span>{{ item[keyLabel] }}</span>
        </button>
      </slot>
    </template>
  </div>
</template>

<script setup>
import PsRichTooltip from '../tooltip/PsRichTooltip.vue'
import { computed } from 'vue'

const props = defineProps({
  /**
   * It sets the layout of the tabheader. eg: standard, underline and folder.
   */
  layout: {
    type: String,
    default: 'standard',
    validator: (value) => ['standard', 'underline', 'folder'].includes(value),
  },
  /**
   * It sets the tabs which will be rendered.
   */
  items: {
    type: Array,
    required: true,
  },
  /**
   * It sets the key label of the items if needed.
   */
  keyLabel: {
    type: String,
    default: 'label',
  },
  /**
   * It sets the key value of the items if needed.
   */
  keyValue: {
    type: String,
    default: 'value',
  },
  /**
   * It disabled the tab header. All mouse events are disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the tab seleced.
   */
  selected: {},
})

const emit = defineEmits(['change', 'update:selected'])

const getComponentClass = computed(() => {
  if (props.disabled) {
    return 'status-disabled'
  } else {
    return 'status-resting'
  }
})

const getIsObject = computed(() => {
  return typeof props.selected === 'object'
})

const getSelected = computed(() => {
  if (props.selected) {
    if (typeof props.selected === 'object' && props.selected[props.keyValue]) {
      return props.selected[props.keyValue]
    } else {
      return props.selected
    }
  } else {
    return false
  }
})

const getItems = computed(() => {
  if (props.items.length > 0 && typeof props.items[0] !== 'object') {
    return props.items.map((item) => {
      return {
        [props.keyLabel]: item,
        [props.keyValue]: item,
      }
    })
  } else {
    return props.items
  }
})

const selectTab = (item) => {
  if (item.disabled !== true) {
    emit('update:selected', getIsObject.value ? item : item[props.keyValue])
    emit('change', getIsObject.value ? item : item[props.keyValue])
  }
}

const getButtonClass = (item) => {
  return computed(() => {
    if (item.disabled) {
      return ['status-disabled']
    } else {
      return { 'status-active': getSelected.value === item[props.keyValue], 'status-enabled': true }
    }
  })
}

</script>

<style>
/* Please, use the file src/assets/scss/components/PsTabHeader.scss */
</style>
