<template>
  <div
    class="psui-el-badge-with-icon"
    :class="getComponentClass"
  >
    <i
      v-if="icon"
      :class="iconClass"
      class="psui-el-badge-with-icon-icon"
    >{{ icon }}</i>
    <span class="psui-el-badge-with-icon-message">{{ message }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  icon: {
    type: String,
    default: 'info',
  },
  /**
   * It set any further css style that might be needed.
   */
  iconClass: {
    type: String,
    default: '',
  },
  message: {
    type: String,
    default: 'Lorem ipsum',
  },
  layout: {
    type: String,
    default: 'grey',
    validator: (value) => ['grey', 'blue', 'green', 'yellow'].includes(value),
  },
})

const getComponentClass = computed(() => {
  return `layout-${props.layout}`
})
</script>
