<template>
  <div
    @mouseenter="open"
    v-click-outside="close()"
    ref="tooltip"
    class="psui-el-tooltip"
  >
    <div
      ref="tooltiptrigger"
      class="psui-el-tooltip-trigger"
    >
      <slot name="trigger" />
    </div>

    <div class="psui-el-tooltip-wrapper">
      <div
        role="menu"
        ref="dialog"
        class="psui-el-tooltip-dialog"
        :class="cssClass"
      >
        <div
          class="psui-el-tooltip-content"
          aria-orientation="vertical"
          :class="getComponentClass"
        >
          <h2 v-if="title">
            {{ title }}
          </h2>
          <div class="psui-el-tooltip-content-wrapper">
            <slot name="content" />
            <button
              v-if="buttonText"
              @click="onClick"
            >
              {{ buttonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, onBeforeUnmount } from 'vue'

const props = defineProps({
  /**
   * It sets the title of the tooltip if needed.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * It sets the label of the button within the tooltip if needed.
   */
  buttonText: {
    type: String,
    default: '',
  },
  /**
   * It sets the layout of the tooltip. eg: white, dark and color.
   */
  layout: {
    type: String,
    default: 'white',
    validator: (value) => ['white', 'dark', 'color'].includes(value),
  },
  /**
   * It sets a additional styling if needed.
   */
  cssClass: {
    type: String,
    default: '',
    required: false,
  },
  /**
   * It sets the vertical position.
   */
  position: {
    type: String,
    default: 'bottom',
    validator: (value) => ['bottom', 'top'].includes(value),
  },
})

const emit = defineEmits(['show', 'click', 'close'])

const show = ref(false)
// const closingTimeout = ref(null)
const dialog = ref(null)
const tooltiptrigger = ref(null)

const getComponentClass = computed(() => {
  return `layout-${props.layout}`
})

onMounted(() => {
  document.addEventListener('resize', updatePosition)
})

onBeforeUnmount(() => {
  document.removeEventListener('resize', updatePosition)
})

const open = () => {
  if (show.value || props.ignoreDialog) return
  emit('show')
  show.value = true
  dialog.value.style.display = 'block'
  dialog.value.style.opacity = 0
  setTimeout(() => {
    updatePosition()
  }, 10)
}

const close = () => {
  if (show.value && dialog.value) {
    emit('close')
    show.value = false
    dialog.value.style.display = 'none'
  }
}

const updatePosition = () => {
  const dialogPosition = dialog.value
  const trigger = tooltiptrigger.value
  const rectDialog = dialogPosition.getBoundingClientRect()
  const rectTrigger = trigger.getBoundingClientRect()
  const windowWidth = document.documentElement.clientWidth
  dialogPosition.style.top = `${rectTrigger.y + rectTrigger.height + 8}px`
  if (rectTrigger.x + rectDialog.width + 20 > windowWidth) {
    dialogPosition.style.left = `${windowWidth - rectDialog.width - 30}px`
  } else if (rectTrigger.x - Math.abs(rectTrigger.width - rectDialog.width) < 0) {
    dialogPosition.style.left = `${rectTrigger.x + 10}px`
  } else if (rectDialog.width >= rectTrigger.width) {
    dialogPosition.style.left = `${rectTrigger.x - (rectDialog.width - rectTrigger.width) / 2}px`
  } else {
    dialogPosition.style.left = `${rectTrigger.x + (rectTrigger.width - rectDialog.width) / 2}px`
  }
  if (props.position == 'top') {
    dialogPosition.style.top = `${rectTrigger.y - rectTrigger.height - 10}px`
  }
  setTimeout(() => {
    dialogPosition.style.opacity = 100
  }, 100)
}

const onClick = ($event) => {
  emit('click', $event)
}
</script>
