<template>
  <div
    ref="PSDropdown"
    class="psui-el-dropdown-menu"
    :class="{ 'is-open': show.value }"
    v-click-outside="close"
  >
    <div
      ref="PSDropdownTrigger"
      v-if="$slots.dropdownTrigger"
      @click="show && !toggleWhenActive ? '' : toggle($event)"
    >
      <slot name="dropdownTrigger" />
    </div>

    <button
      v-else
      @click="show && !toggleWhenActive ? '' : toggle($event)"
      type="button"
      :id="id.value"
      aria-haspopup="true"
      aria-expanded="true"
      ref="PSDropdownTrigger"
    >
      <slot
        v-if="show.value && $slots.buttonLabelOnShow"
        name="buttonLabelOnShow"
      />
      <slot
        v-else
        name="buttonLabel"
      />
    </button>
    <div
      ref="PSDropdownDialog"
      role="menu"
      class="psui-el-dropdown-menu-dialog-wrapper psui-duration-300"
      aria-orientation="vertical"
      :aria-labelledby="id.value"
      :style="{ minWidth: minWidthDropDown }"
    >
      <div class="psui-el-dropdown-menu-dialog">
        <slot name="items" />
      </div>
    </div>
  </div>
</template>

<script setup>
// Figma - 2.3 Dropdown with category divider https://www.figma.com/file/Tto8hrNlSfuPcwd1pfqogF/%E2%9A%A1%EF%B8%8F-Design-System?node-id=1768%3A64886

import { randomString, getParentScrollableEl } from '../../util/GeneralFunctions.js'

import { ref, onBeforeUnmount } from 'vue'

defineExpose({
  close:() => close(),
  open:() => open(),
})

const props = defineProps({
  /**
   * It sets a minimun width for the dropdown menu.
   */
  minWidthDropDown: {
    type: String,
    default: '240px',
  },
  /**
   * It's a boolean responsible for showing a slot within the html tag button.
   */
  buttonLabelOnShow: {
    type: Boolean,
    default: false,
  },
  /**
   * It's a property responsible for toggling the dropdown menu. default: true.
   */
  toggleWhenActive: {
    type: Boolean,
    default: true,
  },
  /**
   * Disable the toogle on click. default: false.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the vertical and horizontal position.
   */
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, vue/require-default-prop
  position: {
    type: String,
    validator: (value) => ['custom'].includes(value),
  },
})

const emit = defineEmits(['open', 'close'])

const show = ref(false)
const id = ref(randomString(8))
// const marginLeft = ref('-0px')
const scrollableParentEl = ref(null)
const PSDropdown = ref(null)
const PSDropdownDialog = ref(null)
const PSDropdownTrigger = ref(null)

// const getMaxWidth = computed(() => {
//   let bounds = PSDropdown.getBoundingClientRect()
//   return document.documentElement.clientWidth - bounds['left'] - 30
// })

onBeforeUnmount(() => {
  unwatchParentScrolling()
})

const toggle = (event) => {
  if (props.disabled) return
  if (!show.value) {
    open()
  } else {
    close()
  }
  event.stopPropagation()
}

const handleEsc = (evt) => {
  if (show.value && evt.keyCode === 27) close()
}

const open = () => {
  emit('open')
  show.value = true
  if (PSDropdownDialog.value) {
    PSDropdownDialog.value.style.opacity = 0
    PSDropdownDialog.value.style.display = 'block'
  }
  setTimeout(() => {
    updatePosition()
    watchParentScrolling()
    document.addEventListener('keyup', handleEsc)
    window.addEventListener('resize', updatePosition)
    // window.addEventListener('click', clickOutside)
  }, 10)
}

const close = () => {
  if (show.value == true) {
    emit('close')
    if (PSDropdownDialog.value !== null) {
      PSDropdownDialog.value.style.display = 'none'
      PSDropdownDialog.value.style.opacity = 0
    }
    show.value = false
    unwatchParentScrolling()
  }
  document.removeEventListener('keyup', handleEsc)
  document.removeEventListener('resize', updatePosition)
  // document.removeEventListener('click', clickOutside)
}

const watchParentScrolling = () => {
  scrollableParentEl.value = getParentScrollableEl(PSDropdown.value)
  if (scrollableParentEl.value) {
    scrollableParentEl.value.addEventListener('scroll', updatePosition)
  }
}

const unwatchParentScrolling = () => {
  if (scrollableParentEl.value) {
    scrollableParentEl.value.removeEventListener('scroll', updatePosition())
  }
}

const updatePosition = () => {
  if (PSDropdownDialog.value === null || PSDropdownTrigger.value === null) return

  const rectTrigger = PSDropdownTrigger.value.getBoundingClientRect()
  const rectDialog = PSDropdownDialog.value.getBoundingClientRect()
  const windowWidth = document.documentElement.clientWidth

  PSDropdownDialog.value.style.position = 'fixed'
  PSDropdownDialog.value.style.top = `${rectTrigger.y + rectTrigger.height}px`
  PSDropdownDialog.value.style.minWidth = `${rectTrigger.width}px`

  if (rectTrigger.x + rectDialog.width + 20 > windowWidth) {
    PSDropdownDialog.value.style.left = `${windowWidth - rectDialog.width - 30}px`
  } else {
    PSDropdownDialog.value.style.left = `${rectTrigger.x}px`
  }

  if (props.position == 'custom') {
    PSDropdownDialog.value.style.top = `${rectTrigger.y}px`
    PSDropdownDialog.value.style.left = `${rectTrigger.x + 100}px`      
  }

  if (rectTrigger.top < 10) {
    close()
    console.warn('The dropdown are too close from the top of the page')
    return
  }

  setTimeout(() => {
    if(PSDropdownDialog.value){
      PSDropdownDialog.value.style.opacity = 1
    }
  }, 10)
}

const clickOutside = (event) => {
  if (!show.value) return
  if (!PSDropdown.value == event.target || !PSDropdown.value?.contains(event.target)) {
    close()
  }
}
</script>
