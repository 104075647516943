<template>
  <div
    class="psui-el-accordion"
    :class="getComponentClass"
  >
    <slot />
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  layout:{
    type: String,
    default: 'medium',
    validator: (value) => ['big', 'medium'].includes(value)
  },
})

const getComponentClass = computed(() => {
  return `layout-${props.layout}`
})

</script>

<style> /* Please, use the file src/assets/scss/components/PsAccordion.scss */</style>
