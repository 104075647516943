<template>
  <div
    class="psui-el-slider"
    :class="`layout-${layout}`"
  >
    <div
      v-if="label"
      class="psui-el-slider-label"
    >
      <span>{{ label }}</span>
    </div>

    <div
      class="psui-el-slider-wrapper"
      :class="{ 'psui-flex psui-items-center': !label && !bubble }"
    >
      <div class="psui-el-slider-wrapper-input">
        <div
          v-if="bubble"
          class="psui-el-slider-range-value"
          :style="{ left: positionBubble }"
        >
          {{ sliderValue }}
        </div>

        <div
          class="slider-bar"
          :style="{ width: progressWidth }"
        />

        <div
          v-for="(barStyle, index) in bgBarStyles"
          :key="index"
          class="slider-bar-bg"
          :style="bgBarStyles[index]"
        />

        <div
          v-for="(bar, index) in barStyles"
          :key="index"
          class="slider-bar-dynamic"
          :style="barStyles[index]"
        />

        <input
          ref="slider"
          type="range"
          :min="min"
          :max="max"
          :step="step"
          class="psui-el-slider-input psui-float-left"
          v-model="sliderValue"
          @input="updateSlider($event)"
        >
      </div>

      <div
        v-if="dividers"
        class="psui-el-slider-grid"
      >
        <div
          v-for="(grid, index) in gridData"
          :key="index"
          class="line"
          :style="{ left: `${grid}%` }"
        />

        <span class="info info-min">{{ min }}</span>
        <span class="info info-max">{{ max }}</span>
      </div>

      <span
        v-if="!label && !bubble"
        class="psui-el-slider-bubble"
      >
        {{ sliderValue }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, onUnmounted, onMounted } from 'vue'

const props = defineProps({
  layout: {
    type: String,
    default: 'default',
    validator: (value) => {
      return ['default', 'rich'].indexOf(value) !== -1
    },
  },
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 100,
    required: true,
  },
  maxValue: {
    type: Number,
    default: 100,
    required: true,
  },
  label: {
    type: String,
    default: '',
  },
  value: {
    type: [Number, String],
    default: 0,
  },
  baseValue: {
    type: [Number, Boolean],
    default: false,
  },
  labelPosition: {
    type: String,
    default: '',
  },
  step: {
    type: Number,
    default: 1,
  },
  bubble: {
    type: Boolean,
  },
  dividers: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Number,
    default: 50,
  },
  gridData: {
    type: Array,
    default() {
      return [0, 30, 55, 80, 100]
    },
  },
  sliderColors: {
    type: Array,
    default() {
      return [
        { start: 140, end: 170, bgColor: '#FF906D' },
        { start: 170, end: 200, bgColor: '#D65C5A' },
      ]
    },
  },
  bgColors: {
    type: Array,
    default() {
      return [
        { start: 0, end: 170, bgColor: '#D6DDE5' },
        { start: 170, end: 200, bgColor: '#A2ACB7' },
      ]
    },
  },
})

const isMounted = ref(false)
const sliderWidth = ref(0)
const sliderValue = ref(0)
const progress = ref(0)
const barStyles = ref([])
const bgBarStyles = ref([])
const slider = ref(null)
const emit = defineEmits(['input'])


const positionBubble = computed(() => {
  const val = sliderValue.value
  const width = sliderWidth.value - 24
  const percent = (val - props.min) / (props.max - props.min)
  const offset = -2
  const position = width * percent + offset
  return `${position}px`
})

const progressWidth = computed(() => {
  if (sliderValue.value > props.maxValue) {
    return '100%'
  }
  return ((sliderValue.value - props.min) / (props.max - props.min)) * 100 + '%'
})

const updateProgress = () => {
  progress.value = Math.round(((sliderValue.value - props.min) / (props.max - props.min)) * 100)
}

const updateSlider = (event) => {
  sliderValue.value = Math.min(event.target.value, props.maxValue)
}

const updateBarStyles = () => {
  barStyles.value = props.sliderColors.map((bar) => getBarStyle(bar))
  bgBarStyles.value = props.bgColors.map((bar) => getBarStyle(bar, false))
}

const resizeHandler = () => {
  sliderWidth.value = slider.value.clientWidth
}

const getBarStyle = (bar, normalizeByProgress = true) => {
  const start = (bar.start / props.max) * 100
  const end = (bar.end / props.max) * 100
  const progress = parseFloat(progressWidth.value)
  let barWidth = end - start
  let barLeft = start
  if (normalizeByProgress) {
    if (progress < start) {
      barWidth = 0
    } else if (progress < end) {
      barWidth = progress - start
    }

    return {
      left: `${barLeft}%`,
      backgroundColor: bar.bgColor,
      width: `${barWidth}%`,
    }
  }
}

watch(sliderValue, (newValue) => {
  updateProgress()
  emit('input', newValue)
  updateBarStyles()
})

watch(
  () => props.value,
  (newValue) => {
    sliderValue.value = newValue
  }
)

watch(
  () => props.sliderColors,
  () => {
    updateBarStyles()
  },
  { immediate: true }
)

window.addEventListener('resize', resizeHandler)

onUnmounted(() => {
  window.removeEventListener('resize', resizeHandler)
})

onMounted(() => {
  isMounted.value = true
  updateProgress()
  updateBarStyles()
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsSlider.scss */
</style>
