<template>
  <div
    class="psui-el-dialog"
    :class="getComponentClass"
  >
    <div class="psui-el-dialog-icon">
      <i class="material-icons-round">{{ icon }}</i>
    </div>
    <div class="psui-el-dialog-wrapper">
      <div class="psui-el-dialog-default">
        <slot>
          <p class="psui-el-dialog-message">
            {{ message }}
          </p>
        </slot>
      </div>
      <div class="psui-el-dialog-action">
        <slot name="action" />
      </div>
    </div>
    <button
      class="psui-el-dialog-close"
      v-if="hasClose"
      @click="onClose"
    >
      <i class="material-icons-round">close</i>
    </button>
  </div>
</template>

<script setup>
// Figma - 3. Inline Dialog https://www.figma.com/file/Tto8hrNlSfuPcwd1pfqogF/%E2%9A%A1%EF%B8%8F-Design-System?node-id=3694%3A55841

import { computed } from 'vue'

const props = defineProps({
  /**
   * It sets the theme of the dialog. eg: informative, success and alert.
   */
  theme: {
    type: String,
    default: 'informative',
    validator: (value) => ['informative', 'success', 'alert'].includes(value),
  },
  /**
   * It set the which will be displayed when component is rendered.
   */
  message: {
    type: String,
    default: '',
  },
  /**
   * It sets the direction of the items within the dialog. See Figma Notifications for full information.
   */
  layout: {
    type: String,
    default: 'horizontal',
    validator: (value) => ['horizontal', 'vertical'].includes(value),
  },
  /**
   * It sets a close button if needed.
   */
  hasClose: {
    type: Boolean,
    default: true,
  },
  /**
   * It sets the text key to get the svg icon in Google Fonts. Make sure to get the correct description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: 'info',
  },
})

const emit = defineEmits(['close'])

const getComponentClass = computed(() => {
  return [`layout-${props.layout}`, `theme-${props.theme}`, `close-${props.hasClose ? 'on' : 'off'}`]
})

const onClose = () => {
  emit('close')
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsDialog.scss */
</style>
