<template>
  <ul
    class="psui-el-dropdown-menu-list"
    :class="`layout-${layout}`"
  >
    <li
      v-for="(item, index) in getItems"
      :key="`${index}-${getKeyValue(item)}`"
      :id="`${index}-${getKeyValue(item)}`"
      :class="{ 'is-selected psui-bg-blue-10 psui-text-blue-60 hover:psui-bg-blue-10 hover:psui-text-blue-60': getSelected === getKeyValue(item) }"
      class="psui-el-dropdown-menu-list-item"
      @mouseover="isHovering = index"
      @mouseout="isHovering = false"
      @click="selectItem(item)"
    >
      <div class="psui-el-dropdown-menu-list-item-left-label">
        {{ getLeftLabel(item) }}

        <div
          v-if="item.description"
          class="psui-font-normal psui-text-gray-50 psui-ml-1"
          :class="{ 'psui-text-blue-60': item.key == getItems.key }"
        >
          {{ item.description }}
        </div>
        <PsIcon
          v-if="item.hasHelper"
          icon="help_outline"
          size="20"
          class="psui-text-blue-60 psui-opacity-0 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
          :display="item.key == getItems.key || isHovering == index ? 'flex' : 'none'"
          @click.stop="emit('openDescriptionModal', { type: item.hasHelper.type, id: item.hasHelper.id, slug: item.hasHelper.slug })"
        />
      </div>

      <template v-if="layout == 'rich'">
        <div class="psui-el-dropdown-menu-list-item-line" />
        <div
          v-if="rightLabelFormatter"
          class="psui-el-dropdown-menu-list-item-rigth-label"
        >
          {{ rightLabelFormatter(item.key, getStudyDataLevel[item.key], getStudyDataLevel) }}
        </div>
        <div
          v-else
          class="psui-el-dropdown-menu-list-item-rigth-label"
        >
          {{ getRightLabel(item) }}
        </div>
      </template>
    </li>
  </ul>
</template>

<script setup>
import PsIcon from '../ui/PsIcon.vue'
import { ref, computed } from 'vue'

const props = defineProps({
  /**
   * It sets the style of items
   */
  layout: {
    type: String,
    default: 'onlytext',
    validator: (value) => {
      return ['onlytext', 'radio', 'icon', 'checkbox', 'switch', 'rich'].indexOf(value) !== -1
    },
  },
  /**
   * It sets the text key which will retrieve a icon from Google Fonts. Make sure to get the correct description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: '',
  },
  /**
   * It sets the items which will be displayed on the dropdown menu.
   */
  items: {
    type: [Array, Object],
    required: true,
  },
  /**
   * It sets the left key label of your items if needed.
   */
  leftLabel: {
    type: [String, Function],
    default: 'left_label',
  },
  /**
   * It sets the right key label of your items if needed.
   */
  rightLabel: {
    type: [String, Function],
    default: 'right_label',
  },
  /**
   * It sets the key label of your items if needed.
   */
  label: {
    type: [String, Function],
    default: 'label',
  },
  /**
   * It sets the key value of your items if needed.
   */
  keyValue: {
    type: [String, Function],
    default: 'value',
  },
  /**
   * It sets the format function to display values.
   */
  rightLabelFormatter: {
    type: Function,
    default: () => '',
  },
  /**
   * It sets the format function to display values.
   */
  studyData: {
    type: [String, Object],
    default: '',
  },
  /**
   * It sets the item selected on the dropdown menu.
   */
  selected: {},
})
const emit = defineEmits(['update:selected', 'change','openDescriptionModal'])

const isHovering = ref(false)

const getSelected = computed(() => {
  if (props.selected !== undefined) {
    if (typeof props.selected === 'object' && props.selected[props.keyValue]) {
      return props.selected[props.keyValue]
    } else {
      return props.selected
    }
  } else {
    return false
  }
})

const getItems = computed(() => {
  return props.items
})

const getStudyDataLevel = computed(() => {
  return props.studyData?.data || props.studyData
})

const selectItem = (item) => {
  emit('update:selected', getKeyValue(item))
  emit('change', item)
}

const getLeftLabel = (item) => {
  if (typeof props.leftLabel == 'function') return props.leftLabel(item)
  if (typeof item === 'string') return item
  return item[props.leftLabel]
}

const getRightLabel = (item) => {
  if (typeof props.rightLabel == 'function') return props.rightLabel(item)
  if (typeof item === 'string') return item
  return item[props.rightLabel]
}

// const getLabel = (item) => {
//   if(typeof props.label == 'function') return props.label(item)
//   if(typeof item === 'string') return item
//   return item[props.label]
// }

const getKeyValue = (item) => {
  if (typeof props.keyValue == 'function') return props.keyValue(item)
  if (typeof item === 'string') return item
  return item[props.keyValue]
}
</script>
