<template>
  <div
    class="psui-el-radio"
    :class="[getComponentClass, { disabled: disabled }]"
    v-bind="getComponentAttrs"
  >
    <input
      type="radio"
      :checked="checked"
      :disabled="disabled"
      :label="label"
      :name="name"
      :id="getInputId"
      v-bind="inputAttrs"
      @change="emit('change')"
    >
    <label
      :for="getInputId"
      class="psui-el-checkmark"
    >
      <slot name="content">
        <span>{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script setup>
import { randomString } from '../../util/GeneralFunctions.js'
import { computed, useAttrs } from 'vue'

const emit = defineEmits(['change'])

const props = defineProps({
  /**
   * It sets the label of the checkbox input.
   */
  label: {
    type: String,
    default: '',
  },
  name: {
    type: String,
    default: '',
  },
  /**
   * It disables the checkbox input.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * Sets the input as checked
   */
  checked: {
    type: Boolean,
    default: false,
  },
  /**
   * Add html attributes to the element input
   */
  inputAttrs: {
    type: Object,
    default: () => ({}),
  },
  /**
   * It set the of the checkbox input. eg: big and small.
   */
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['big', 'small'].includes(value),
  },
  /**
   * It set the layout of the checkbox input. eg: default and mixed.
   */
})

const attrs = useAttrs()

const getComponentClass = computed(() => {
  return `size-${props.size}`
})

const getInputId = computed(() => {
  if (props?.inputAttrs?.id) {
    return props.inputAttrs.id
  } else if (attrs?.id) {
    return attrs.id
  } else {
    return randomString(16)
  }
})

const getComponentAttrs = computed(() => {
  let componentAttrs = { ...attrs }
  delete componentAttrs.id
  return componentAttrs
})
</script>
