<template>
  <div
    class="psui-el-toggle"
    role="group"
  >
    <button
      type="button"
      v-for="(item, index) in items"
      :key="getKeyValue(item) + index"
      @click="selectOption(item)"
      :class="{ 'status-active': selected == item }"
    >
      {{ getKeyLabel(item) }}
    </button>
  </div>
</template>

<script setup>
//FIGMA CONTROLS & SELECTORS https://www.figma.com/file/Tto8hrNlSfuPcwd1pfqogF/%E2%9A%A1%EF%B8%8F-Design-System?node-id=1768%3A64852

const emit = defineEmits(['change', 'update:selected'])

const props = defineProps({
  /**
   * It sets the items which will be used to create the toggle tab. See Figma - Controls & Selector for more information.
   */
  items: {
    type: Array,
    required: true,
  },
  /**
   * It sets the value selected.
   */
  selected: {},
  /**
   * It sets the key label of your items if needed.
   */
  keyLabel: {
    type: [String, Function],
    default: 'label',
  },
  /**
   * It sets the key value of your items if needed.
   */
  keyValue: {
    type: [String, Function],
    default: 'value',
  },
})

const selectOption = (item) => {
  emit('update:selected', item)
  emit('change', item)
}

const getKeyLabel = (item) => {
  if (typeof props.keyLabel == 'function') return props.keyLabel(item)
  if (typeof item === 'object') return item[props.keyLabel]
  return item
}

const getKeyValue = (item) => {
  if (typeof props.keyValue == 'function') return props.keyValue(item)
  if (typeof item === 'object') return item[props.keyLabel]
  return item
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsToggle.scss */
</style>
