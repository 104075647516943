<template>
  <div
    ref="tableWrapper"
    class="psui-el-table-results-wrapper"
    :class="`table-${layout}`"
    :style="{ maxHeight: tableMaxHeight }"
  >
    <table
      ref="table"
      class="psui-el-table-results"
      :class="[`layout-${layout}`, { 'is-sticky': isSticky }]"
    >
      <slot name="header" />

      <tbody>
        <tr
          v-for="(item, index) in getRows"
          :key="index"
          :class="[
            getStatusClass(item),
            {
              'is-disabled': item.is_disabled,
              'is-first': item.deep == 0,
              'psui-hidden': checkRowIsCollapsed(item) || item.not_visible,
              'is-last': item.is_last,
              'is-active': selectedRow == item.id,
              'removed-class': (item.slug == 'removed_measures' || item.is_excluded) && layout == 'flexible',
              'appended-row': item.is_appended,
            },
            `${item.type}`,
          ]"
          :id="item.id"
          :data-group="item.id"
          @mouseenter="onRowHover(index, item, 'enter')"
          @mouseleave="onRowHover(false, item, 'leave')"
        >
          <td :style="item.background_color ? `background-color: ${getBackgroundColor(item.background_color)}; transition: background-color 0.5s ease;` : ''">
            <div class="psui-flex psui-relative">
              <div
                v-tooltip="{
                  classes: 'layout-gray-50',
                  content: item.remove_add_tooltip,
                }"
              >
                <PsIcon
                  v-if="item.remove_add_button"
                  :icon="item.remove_add_button_icon"
                  class="psui-flex psui-text-gray-40 pl-4 psui-cursor-pointer leading-none hover:psui-text-blue-60 transition-all"
                  size="15"
                  display="flex"
                  @click.native="emit('removeOrAddButtonChange', item, $event)"
                />
              </div>
              <div
                class="psui-flex psui-items-center psui-space-x-3"
                :style="{ paddingLeft: `${item.deep * 16}px` }"
              >
                <PsIcon
                  v-if="(!item.last_deep || item.type === 'total') && !item.is_appended"
                  icon="expand_more"
                  size="24"
                  class="actions-button psui-transition psui-transform psui-cursor-pointer"
                  :icon-classes="item.is_disabled ? 'psui-text-gray-40' : getIconClasses(item)"
                  display="flex"
                  :class="[
                    checkRowIsVisible(item) ? 'psui-rotate-0' : 'psui--rotate-90',
                    { 'psui--rotate-90': item.is_disabled },
                    { 'psui-pointer-events-none psui--rotate-90': item.items.length == 0 },
                  ]"
                  @click.native="onCollapse(item, $event)"
                />

                <div v-if="item.is_appended">
                  <span class="psui-text-small psui-font-bold psui-text-blue-60 psui-ml-2">
                    {{ item.title }}
                  </span>
                </div>

                <div
                  v-else-if="item.is_disabled || item.tooltip_text"
                  class="psui-inline-flex psui-cursor-default sticky"
                  :class="{ 'is-last-deep': item.last_deep }"
                  v-tooltip="getTooltipContent(item)"
                >
                  <div class="flex psui-items-center psui-relative">
                    <PsIcon
                      v-if="item.has_blocked_icon"
                      icon="do_disturb_alt"
                      size="14"
                      icon-classes="psui-absolute psui--left-1 psui-text-gray-50"
                    />
                    <p
                      class="title psui-text-gray-50 opacity-100-childrens-on-hover"
                      :style="item.text_color ? `color: ${item.text_color};` : ''"
                    >
                      {{ item.title }}
                      
                      <PsIcon
                        v-if="item.has_helper"
                        icon="info_outline"
                        size="16"
                        class="psui-text-gray-40 hover:psui-text-blue-60 psui-opacity-0 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
                        display="flex"
                        @click.native="emit('openDescriptionModal', { type: 'helper', slug: `table-results-${item.helper_slug}` })"
                      />
                    </p>
                  </div>
                </div>

                <p
                  v-else
                  class="title opacity-100-childrens-on-hover"
                  :class="[{ 'psui-font-bold': item.type == 'total' }, { 'is-last-deep': item.last_deep }]"
                >
                  {{ item.title }}

                  <PsIcon
                    v-if="item.has_helper"
                    icon="info_outline"
                    size="16"
                    class="psui-opacity-0 psui-text-gray-40 hover:psui-text-blue-60 psui-transition psui-leading-none psui-cursor-pointer psui-ml-1"
                    display="flex"
                    @click.native="emit('openDescriptionModal', { type: 'helper', slug: `table-results-${item.helper_slug}` })"
                  />
                </p>

                <PsIcon
                  v-if="item.has_badge"
                  :icon="item.has_badge"
                  size="20"
                  class="badge psui-text-green-70 psui-leading-none psui-ml-0"
                  display="flex"
                />
              </div>
              <div
                class="actions psui-space-x-3 flex justify-between"
                :style="layout !== 'flexible' ? { paddingLeft: `${item.deep * 16}px` } : {}"
              >
                <PsRichTooltip
                  v-if="shouldShowIcon(item)"
                  position="top"
                  layout="gray"
                  class="psui-inline-flex psui-cursor-default"
                  :class="[isHoveringRow === index ? 'opacity-1' : 'opacity-0']"
                  :ignore-dialog="getIgnoreDialogIcon(item)"
                >
                  <template #trigger>
                    <PsIcon
                      :icon="getIcon(item)"
                      :class="[
                        'psui-flex psui-text-gray-40 psui-cursor-pointer leading-none hover:psui-text-blue-60 psui-gap-3 transition-all',
                        layout === 'flexible' ? 'px-1 psui-py-0' : 'psui-px-5 psui-py-1'
                      ]"
                      size="16"
                      display="flex"
                      @click.native="executeCallback(item)"
                    />
                  </template>
                  <template #content>
                    <p class="psui-font-bold psui-text-gray-80 psui-text-xsmall">
                      {{ getText(item) }}
                    </p>
                  </template>
                </PsRichTooltip>

                <PsDropdown
                  ref="PSDropdown"
                  v-if="shouldShowDropdown(item)"
                  :class="[isHoveringRow === index ? 'opacity-1' : 'opacity-0']"
                  position="custom"
                >
                  <template #dropdownTrigger>
                    <PsIcon
                      icon="more_horiz"
                      size="18"
                      class="psui-flex psui-text-gray-40 psui-cursor-pointer leading-none hover:psui-text-blue-60 psui-gap-3 psui-px-5 psui-py-1 transition-all"
                      display="flex"
                    />
                  </template>
                  <template #items>
                    <ul class="psui-w-full psui-font-bold psui-my-3">
                      <li
                        v-for="(action, index) in item.actions"
                        :key="index"
                        :title="action.title"
                        class="psui-text-gray-60 hover:psui-bg-blue-10 hover:psui-text-blue-60 psui-font-bold psui-cursor-pointer psui-flex psui-items-center psui-gap-3 psui-px-5 psui-py-1 transition-all"
                        @click="executeCallback(item, action)"
                      >
                        <span class="psui-w-auto psui-text-small">{{ action.title }}</span>
                      </li>
                    </ul>
                  </template>
                </PsDropdown>
              </div>
            </div>
          </td>

          <template v-for="(columnGroup, indexColumn) of columnGroups">
            <td
              :style="item.background_color ? `background-color: ${getBackgroundColor(item.background_color)}; transition: background-color 0.5s ease;` : ''"
              v-for="column of columnGroup.columns"
              class="psui-transition"
              :key="indexColumn + '-' + columnGroup.key + '-' + column.key"
              :data-test-id="column.key"
              :class="`column-${column.key}`"
            >
              <div
                v-if="layout != 'comparison'"
                class="psui-space-x-2 psui-show-childrens-on-hover"
                :class="column.isCenteredContent ? 'psui-justify-center' : 'psui-justify-end'"
              >
                <PsTooltip v-if="isSelectedRow(column, item)">
                  <template #trigger>
                    <PsIcon
                      icon="close"
                      size="16"
                      class="psui-cursor-pointer"
                      icon-classes="psui-text-blue-60 psui-leading-none psui-transition"
                      display="flex"
                      @click.native="resetPolicyImpactItemSelected(item, column)"
                    />
                  </template>
                  <template #content>
                    Close projections
                  </template>
                </PsTooltip>

                <PsTooltip
                  v-else-if="
                    (column.hasProjections && !item.is_disabled && item.disable_projection_select !== true && layout != 'flexible') ||
                      (column.hasProjections && item.disable_projection_select != true && item.deep != 0 && layout == 'flexible')
                  "
                >
                  <template #trigger>
                    <PsIcon
                      icon="bar_chart"
                      size="16"
                      class="psui-cursor-pointer"
                      icon-classes="psui-text-blue-60 psui-opacity-0 psui-leading-none psui-transition"
                      display="flex"
                      @click.native="onSelectRow(item, column, $event)"
                    />
                  </template>
                  <template #content>
                    Show projections in the chart
                  </template>
                </PsTooltip>
                <p
                  class="ps-table-cell-value"
                  v-if="!column.isSwitch"
                >
                  <template v-if="column.hasScoreHelper">
                    <PsTooltip layout="blue">
                      <template #trigger>
                        {{ getItemContent(column, item) }}
                      </template>
                      <template #content>
                        <p class="psui-text-xsmall psui-font-bold">
                          {{ column.hasScoreHelper.title }}
                        </p>
                        {{ column.hasScoreHelper.description }}
                      </template>
                    </PsTooltip>
                  </template>

                  <template v-else>
                    {{ getItemContent(column, item) }}
                  </template>
                </p>
                <PsProgressBar
                  v-if="column.isChart && formatFunction && item.data[column.key] != null"
                  :value="formatFunction(column.key, item.data[column.key], item.data) == '--' ? 0 : item.data[column.key]"
                  :force-break-even="formatFunction(column.key, item.data[column.key], item.data) === '--' ? true : false"
                />

                <PsProgressBar
                  v-else-if="column.isChart && item.data[column.key] != null"
                  :value="item.data[column.key] == '--' ? 0 : item.data[column.key]"
                  :force-break-even="item.data[column.key] === '--' ? true : false"
                />
                <div
                  v-if="column.isSwitch && item.data[column.key] != null"
                  class="psui-inline-flex"
                  v-tooltip="{
                    content: item.tooltip_text ? `<p class='psui-text-white psui-text-xsmall psui-font-bold'>${item.tooltip_text}</p>` : false,
                    classes: 'layout-blue',
                  }"
                >
                  <div class="flex psui-items-center psui-relative">
                    <span
                      v-if="item.has_flag"
                      class="psui-w-2 psui-h-2 psui-bg-yellow-20 psui-absolute psui--right-2 psui--top-2 psui-rounded-md"
                    />
                    <PsSwitch
                      :disabled="item.data.is_disabled || item.is_excluded"
                      :value="item.data[column.key]"
                      :background-color="switchButtonBackgroundColor"
                      class="psui-justify-self-center"
                      :class="{ 'psui-pointer-events-none': preventClickOnSwitchButtons }"
                      size="small"
                      @change="emit('switchButtonItemChanged', item, $event)"
                    />
                  </div>
                </div>
              </div>

              <!-- only comparison layout -->
              <div v-else>
                <div class="psui-py-4 psui-px-6">
                  <PsTagScope
                    v-if="column.renderType && column.renderType == 'tag_scope'"
                    :included="item.data[column.key] == true"
                  />

                  <PsBarChart
                    v-else-if="item.data[column.key] != 0 && column.renderType && column.renderType == 'bar_chart'"
                    :show-number="item.data[column.key] != 0 ? true : false"
                    :total="
                      formatFunction
                        ? formatFunction(column.key, item.data[column.key], item.data) == '--'
                          ? 0
                          : formatFunction(column.key, item.data[column.key], item.data)
                        : item.data[column.key]
                    "
                    :fill-width="getPsBarChartWidth(column.key, item.data[column.key])"
                  />

                  <p
                    class="ps-table-cell-value"
                    v-else-if="item.data[column.key] != 0"
                  >
                    {{ formatFunction(column.key, item.data[column.key], item.data) }}
                  </p>
                </div>
              </div>
            </td>
          </template>
        </tr>
      </tbody>
      <slot name="footer" />
    </table>
  </div>
</template>

<script setup>
import PsRichTooltip from '../tooltip/PsRichTooltip.vue'
import PsIcon from '../ui/PsIcon.vue'
import PsProgressBar from '../badges-and-tags/PsProgressBar.vue'
import PsTagScope from '../badges-and-tags/PsTagScope.vue'
import PsBarChart from '../data-graphics/PsBarChart.vue'
import PsTooltip from '../tooltip/PsTooltip.vue'
import PsSwitch from '../controls/PsSwitch.vue'
import tailwindConfig from '../../../tailwind.config'

import { ref, computed, watch, onMounted, onBeforeUnmount, getCurrentInstance  } from 'vue'

const props = defineProps({
  /**
   * It sets the layout of the table. eg: results or comparison
   */
  layout: {
    type: String,
    default: 'results',
    validator: (value) => {
      return ['results', 'comparison', 'flexible'].indexOf(value) !== -1
    },
  },
  /**
   * It sets the max-height to table.
   */
  tableMaxHeight: {
    type: String,
    default: '540px',
  },
  /**
   * It sets if the lines should start collapsed.
   */
  showRowsCollapsed: {
    type: Boolean,
    default: true,
  },
  /**
   * It sets the format function to display values.
   */
  formatFunction: {
    type: Function,
    default: () => '',
  },
  /**
   * It sets the disabled texts conditionals.
   */
  disabledText: {
    type: String,
    default: '',
  },
  /**
   * It sets the hidden items.
   */
  hiddenItems: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the hidden items index.
   */
  itemsHiddenIndexes: {
    type: [Array, undefined],
    default: undefined,
  },
  /**
   * It sets the values which will be use to render the body.
   */
  summaryData: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the values which will be use to render the body.
   */
  columnGroups: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the values which will be use to render the body.
   */
  existingColumnGroup: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets the values which will be use to render the body.
   */
  rows: {
    type: Array,
    default() {
      return []
    },
  },
  /**
   * It sets if rows is collapsible.
   */
  isCollapsible: {
    type: Boolean,
    default: true,
  },
  /**
   * It sets the level to show opened rows.
   */
  customLevelOpened: {
    type: Number,
    default: 1,
  },
  /**
   * It sets the values which will be use to render the body.
   */
  policies: {
    type: Array,
    default() {
      return []
    },
  },
  switchButtonBackgroundColor: {
    type: String,
    default: '',
  },
  preventIsDisabledOnItemsValue: {
    type: Boolean,
    default: false,
  },
  preventClickOnSwitchButtons: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['policy-selected', 'collapse-row', 'switchButtonItemChanged', 'removeOrAddButtonChange','setPolicyItemSelected','openDescriptionModal'])

defineExpose({resetPolicy: (item, columnKey) => resetPolicyImpactItemSelected(item, columnKey)})

const collapsedRows = ref([])
const isHoveringRow = ref(false)
const selectedRow = ref(null)
const policyItemSelected = ref(null)
const columnSelectedKey = ref(null)
const isSticky = ref(false)
const tableWrapper = ref(null)
const instance = getCurrentInstance()

const addRow = (item, deep = 0, index, rows) => {
  item.deep = deep
  item.index = index
  rows.push(item)
  item.last_deep = item.items ? false : true
  if (item.items) {
    const items_child = [...item.items]
    for (let indexChild = 0; indexChild < items_child.length; indexChild++) {
      const item_child = items_child[indexChild]
      item_child.is_last = indexChild + 1 === items_child.length ? true : false
      addRow(item_child, deep + 1, `${index}-${indexChild}`, rows)
    }
  }
}

const getRows = computed(() => {
  const rows = []
  for (let index = 0; index < props.summaryData.length; index++) {
    const item = props.summaryData[index]
    addRow(item, 0, `${index}`, rows)
  }
  return rows
})

watch(
  () => getRows.value,
  () => {
    setCollapsedRows()
  },
  { deep: true },
  { immediate: true }
)

watch(
  ()=> props.itemsHiddenIndexes, 
  () => {
    setCollapsedRows()
})

watch(
  ()=>props.showRowsCollapsed, 
  () => {
  setCollapsedRows()
})

onMounted(() => {
  setCollapsedRows()
  tableWrapper.value.addEventListener('scroll', handleTableScroll)
})

onBeforeUnmount(() => {
  tableWrapper.value.removeEventListener('scroll', handleTableScroll)
})

const resetPolicyImpactItemSelected = (item, columnKey = 'forecast_emissions_savings') => {
  emit('setPolicyItemSelected', { item, columnSelectedKey: columnKey })
  selectedRow.value = null
}

const setCollapsedRows = () => {
  let newRowsCollpased = [...(props.itemsHiddenIndexes || [])]

  if (props.itemsHiddenIndexes && props.layout != 'flexible') return
  if (props.showRowsCollapsed || props.layout == 'flexible') {
    getRows.value.forEach((element) => {
      const matchForAll = Boolean(element?.items?.length && (props.customLevelOpened == null || element.index.split('-').length > props.customLevelOpened))
      const matchForFlexibleLayout = Boolean(element.isCollapsed === true)
      if (matchForAll || matchForFlexibleLayout) {
        newRowsCollpased.push(element.index)
      }
    })
  }

  collapsedRows.value = newRowsCollpased
}

const getIconClasses = (item) => {
  return checkRowIsVisible(item) ? 'psui-text-gray-40' : 'psui-text-blue-60'
}

const checkRowIsVisible = (item) => {
  return collapsedRows.value.indexOf(item.index) < 0
}

const checkRowIsCollapsed = (item) => {
  let isHidden = false
  for (let index = 0; index < collapsedRows.value.length; index++) {
    const item_hidden = collapsedRows.value[index]
    if (item.index.startsWith(`${item_hidden}-`)) {
      isHidden = true
      break
    }
  }
  return isHidden
}

const onCollapse = (item, $event) => {
  if (props.itemsHiddenIndexes && props.itemsHiddenIndexes.some((it) => it == item.index.toString()) && props.layout == 'flexible') {
    return
  }
  const index = collapsedRows.value.indexOf(item.index)

  if (index > -1) {
    collapsedRows.value.splice(index, 1)
    delete item.isCollapsed
  } else {
    collapsedRows.value.push(item.index)
    item.isCollapsed = true
  }
  instance?.proxy?.$forceUpdate()
  emit('collapse-row', collapsedRows.value, $event)
}

const onRowHover = (index, item, type) => {
  const parent = document.getElementById(`${item.id}`)
  const children = parent?.children
  if (type == 'enter' && item.index != 0 && item.deep != 0 && props.layout == 'flexible') {
    for (let i = 0; i < children.length; i++) {
      children[i].classList.add('hover-color')
    }
  } else if (parent) {
    parent.querySelectorAll('.hover-color').forEach((child) => {
      child.classList.remove('hover-color')
    })
  }
  isHoveringRow.value = index
}

const getIgnoreDialogIcon = (item) => {
  return !shouldShowIcon(item) || item.actions[0].ignoreDialog
}

const shouldShowIcon = (item) => {
  if (item?.actions?.length === 1) return true
  else return false
}

const shouldShowDropdown = (item) => {
  if (item?.actions?.length > 1) return true
  else return false
}

const getIcon = (item) => {
  if (item?.actions?.length === 1) return item.actions[0].icon
}

const getText = (item) => {
  if (item?.actions?.length === 1) return item.actions[0].text
}

const getItemContent = (column, item) => {
  if (column.isSwitch) return

  if ((props.formatFunction && !item.is_disabled) || props.preventIsDisabledOnItemsValue) {
    return props.formatFunction(column.key, item.data[column.key], item.data, item.study_id)
  } else if (item.is_disabled) {
    return '--'
  } else {
    return item.data[column.key]
  }
}

const executeCallback = (item, action) => {
  if (item?.actions?.length === 1) item.actions[0].callback(item)
  else action?.callback(item)
  Object.values(instance.proxy.$refs)
    .flat(Infinity)
    .forEach((component) => {
      if (component?.$options?._componentTag == 'PsDropdown') {
        component.close()
      }
    })
}

const getStatusClass = (item) => {
  return checkRowIsVisible(item) ? 'opened' : 'closed'
}

const onSelectRow = (item, column, $event) => {
  if (props.layout != 'flexible') {
    selectedRow.value = item.id
    policyItemSelected.value = { ...item }
    delete policyItemSelected.value.items
    columnSelectedKey.value = column.key
  }
  emit('policy-selected', { item: item, column: column }, $event)
}

const isSelectedRow = (column, item) => {
  if (!item.id) {
    return selectedRow.value === item.id && columnSelectedKey.value == column.key
  }
  return column.hasProjections && !item.is_disabled && selectedRow.value == item.id && columnSelectedKey.value == column.key
}

const getBackgroundColor = (value) => {
  if (value && value.includes('psui-bg-')) {
    return tailwindConfig.theme.colors[value.replace('psui-bg-', '')]
  }
  return value || '#ffffff'
}

const getTooltipContent = (item) => {
  let content = {
    classes: 'layout-gray',
    content: '',
  }
  if (item.tooltip_text && props.layout == 'flexible') {
    content.classes = 'layout-blue'
    content.content = item.tooltip_text
  } else if (item.tooltip_text) {
    content.content = `<p class="psui-font-bold psui-text-gray-80 psui-text-xsmall">${item.tooltip_text}</p>`
  } else if (item.is_disabled) {
    content.content = `<p class="psui-font-bold psui-text-gray-80 psui-text-xsmall">${item.title} buildings are <br>not allowed.</p>`
  }
  return content
}

const getPsBarChartWidth = (key, value) => {
  const max = Math.max(...getRows.value.map((item) => item.data[key]))
  return (value * 100) / max
}

const setVerticalStyleWhenScroolingForFlexibleLayout = (tableWrapper) => {
  if (tableWrapper.scrollTop > 0 && tableWrapper.classList.contains('table-flexible')) {
    tableWrapper.getElementsByTagName('thead')[0].style.boxShadow = '0px 0px 8px rgba(0, 0, 0, 0.04), 0px 2px 5px rgba(0, 0, 0, 0.08)'
  } else if (tableWrapper.classList.contains('table-flexible')) {
    tableWrapper.getElementsByTagName('thead')[0].style.boxShadow = '0 0 0 rgba(0, 0, 0, 0)'
  }
}

const setHorizontalStyleWhenScrollingForFlexibleLayout = (tableWrapper) => {
  const firstHeaderElement = tableWrapper.querySelector('thead > tr:first-child > th:first-child')
  const secondHeaderElment = tableWrapper.querySelector('thead > tr:not(:first-of-type) > th:nth-child(3)')
  const getAllOtherBodyElements = tableWrapper.querySelectorAll('tbody > tr > td:nth-child(3)')
  if (tableWrapper.scrollLeft > 0 && tableWrapper.classList.contains('table-flexible')) {
    firstHeaderElement.classList.add('pseudo-line')
    secondHeaderElment.style.boxShadow = 'inset -1px 0px 0px #D6DDE5'
    getAllOtherBodyElements.forEach((element) => {
      element.style.boxShadow = 'inset -1px 0px 0px #D6DDE5'
    })
  } else if (tableWrapper.classList.contains('table-flexible')) {
    firstHeaderElement.classList.remove('pseudo-line')
    secondHeaderElment.style.boxShadow = '0 0 0 rgba(0, 0, 0, 0)'
    getAllOtherBodyElements.forEach((element) => {
      element.style.boxShadow = '0 0 0 rgba(0, 0, 0, 0)'
    })
  }
}

const handleTableScroll = () => {
  setVerticalStyleWhenScroolingForFlexibleLayout(tableWrapper.value)
  setHorizontalStyleWhenScrollingForFlexibleLayout(tableWrapper.value)
  if (tableWrapper.value.scrollLeft > 0) {
    isSticky.value = true
  } else {
    isSticky.value = false
  }
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsTableResults.scss */
</style>
