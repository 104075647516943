<template>
  <thead>
    <tr>
      <th
        v-for="columnGroup of header"
        :key="columnGroup.key"
        :colspan="columnGroup.columns.reduce((prev, cur) => (cur.isActive ? prev + 1 : prev), 0)"
      >
        <div class="psui-flex psui-space-x-1 psui-items-center psui-show-childrens-on-hover">
          <p class="title">
            {{ columnGroup.title }}
          </p>
          <PsIcon
            icon="info_outline"
            size="16"
            class="psui-cursor-pointer psui-text-blue-40 hover:psui-text-blue-60 psui-opacity-0 psui-leading-none psui-transition"
            :style="{ display: 'flex' }"
            @click="emit('click-column-group-helper', columnGroup, $event)"
          />
        </div>
      </th>
    </tr>

    <tr>
      <template v-for="(columnGroup, columnGroupIndex) of header">
        <th
          v-for="column of columnGroup.columns"
          :key="`${columnGroup.key}-${column.key}`"
          :data-test-id="column.key"
        >
          <div
            class="psui-flex psui-flex-col psui-justify-center"
            :class="columnGroupIndex == 0 ? 'psui-items-center' : 'psui-items-end'"
            :data-index="columnGroupIndex"
            @mouseleave="emit('column-alert-hide', column, $event, false)"
          >
            <div class="psui-show-childrens-on-hover absolute-childrens psui-mb-px">
              <p
                class="title"
                :class="{'is-warning-column': !!column.warning}"
                v-if="column.title"
              >
                {{ column.title }}
              </p>
              <span
                v-if="column.warning"
                class="warning-svg-position"
                @mouseover="emit('column-alert-show', column, $event, true)"
              >
                <span class="cursor-pointer">
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                  >
                    <circle
                      cx="5"
                      cy="5"
                      r="4.5"
                      fill="#EDAB3E"
                    />
                  </svg>
                </span>
              </span>

              <PsIcon
                icon="info_outline"
                size="16"
                class="helper psui-cursor-pointer psui-text-gray-40 hover:psui-text-blue-60 psui-opacity-0 psui-leading-none psui-transition"
                :style="{ display: 'flex', marginTop: '1px' }"
                @click="emit('click-column-helper', column, $event)"
              />

              <PsIcon
                v-if="showOrder"
                :icon="orderDirection == 'asc' ? 'arrow_downward' : 'arrow_upward'"
                :type="orderDirection == 'asc' ? 'arrow_upward' : 'arrow_upward'"
                size="16"
                class="helper psui-cursor-pointer psui-text-blue-50 psui-opacity-0 psui-leading-none psui-transition"
                :style="{ display: 'flex', marginTop: '1px' }"
                @click="emit('click-order-column', column)"
              />
            </div>
            <p
              class="description"
              v-if="column.description"
            >
              {{ column.description }}
            </p>
          </div>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script setup>
import PsIcon from '../ui/PsIcon.vue'

import { computed, getCurrentInstance } from 'vue'

defineProps({
  /**
   * It sets the title for the first column.
   */
  firstColumnTitle: {
    type: String,
    default: '',
  },
  /**
   * It sets the description for the first column.
   */
  firstColumnDescription: {
    type: String,
    default: '',
  },
  /**
   * It sets the values which will be use to render the header.
   */
  header: {
    type: Array,
    required: true,
    default: () => {
      return []
    },
  },
  /**
   * this sets whether sorting will be displayed.
   */
  showOrder: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click-column-group-helper', 'click-column-helper', 'click-order-column', 'column-alert-show', 'column-alert-hide'])

const parent = getCurrentInstance().proxy.$parent

const columnsSelectedForStudy = computed(() => {
  return parent.columnsSelectedForStudy
})

const orderColumn = computed(() => {
  return parent.orderColumn
})

const orderDirection = computed(() => {
  return parent.orderDirection
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsTableResults.scss */
</style>
