<template>
  <thead>
    <tr>
      <th rowspan="2">
        <div v-if="firstColumnTitle">
          <p class="title">
            {{ firstColumnTitle }}
          </p>
        </div>
        <div v-if="firstColumnDescription">
          <p class="description">
            {{ firstColumnDescription }}
          </p>
        </div>
      </th>

      <th
        v-for="columnGroup of header"
        :key="columnGroup.key"
        :colspan="columnGroup.columns.reduce((prev, cur) => (cur.isActive ? prev + 1 : prev), 0)"
      >
        <div class="psui-flex psui-space-x-1 psui-items-center psui-show-childrens-on-hover">
          <p class="title">
            {{ columnGroup.title }}
          </p>
          <PsIcon
            icon="info"
            size="18"
            class="psui-cursor-pointer psui-text-blue-50 psui-opacity-0 psui-leading-none psui-transition"            
            :style="{ display: 'flex' }"
            @click="emit('click-column-group-helper', columnGroup, $event)"
          />
        </div>
      </th>
    </tr>

    <tr>
      <template v-for="columnGroup of header">
        <th
          v-for="column of columnGroup.columns"
          :key="`${columnGroup.key}-${column.key}`"
        >
          <div class="psui-flex psui-flex-col psui-justify-center psui-items-center psui-text-center">
            <div class="psui-show-childrens-on-hover absolute-childrens psui-mb-px">
              <p
                class="title"
                v-if="column.title"
              >
                {{ column.title }}
              </p>

              <PsIcon
                icon="info"
                size="16"
                class="helper psui-cursor-pointer psui-text-blue-50 psui-opacity-0 psui-leading-none psui-transition"                
                :style="{ display: 'flex', marginTop: '1px' }"
                @click="emit('click-column-helper', column, $event)"
              />

              <PsIcon
                v-if="showOrder"
                :icon="orderDirection == 'asc' ? 'arrow_downward' : 'arrow_upward'"
                :type="orderDirection == 'asc' ? 'arrow_upward' : 'arrow_upward'"
                size="16"
                class="helper psui-cursor-pointer psui-text-blue-50 psui-opacity-0 psui-leading-none psui-transition"                
                :style="{ display: 'flex', marginTop: '1px' }"
                @click="emit('click-order-column', column)"
              />
            </div>
            <p
              class="description"
              v-if="column.description"
            >
              {{ column.description }}
            </p>
          </div>
        </th>
      </template>
    </tr>
  </thead>
</template>

<script setup>
import PsIcon from '../ui/PsIcon.vue'
import { computed, getCurrentInstance } from 'vue'

defineProps({
  /**
   * It sets the title for the first column.
   */
  firstColumnTitle: {
    type: String,
    default: '',
  },
  /**
   * It sets the description for the first column.
   */
  firstColumnDescription: {
    type: String,
    default: '',
  },
  /**
   * It sets the values which will be use to render the header.
   */
  header: {
    type: Array,
    required: true,
    default: () => {
      return []
    },
  },
  /**
   * this sets whether sorting will be displayed.
   */
  showOrder: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['click-column-group-helper', 'click-column-helper', 'click-order-column'])

const parent = getCurrentInstance().proxy.$parent

const columnsSelectedForStudy = computed(() => {
  return parent.columnsSelectedForStudy
})

const orderColumn = computed(() => {
  return parent.orderColumn
})

const orderDirection = computed(() => {
  return parent.orderDirection
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsTableResults.scss */
</style>
