<template>
  <div
    class="psui-el-toast"
    :class="getComponentClass"
  >
    <i class="material-icons-round psui-el-toast-icon">{{ icon }}</i>
    <p class="psui-el-toast-message">
      {{ message }}
    </p>
    <div class="psui-el-toast-actions">
      <slot />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  /**
   * It sets the layout of the toast. eg: info, success, warning and error.
   */
  layout: {
    type: String,
    default: 'info',
    validator: (value) => ['info', 'success', 'warning', 'error'].includes(value),
  },
  /**
   * It sets a supportive layout. eg: soft and intese. See Figma - Notification for full information.
   */
  fill: {
    type: String,
    default: 'intense',
    validator: (value) => ['soft', 'intense'].includes(value),
  },
  /**
   * It sets the message which will be displayed when component is rendered.
   */
  message: {
    type: String,
    default: '',
  },
  /**
   * It sets the text key to get the svg icon in Google Fonts. Make sure to get the correct description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: '',
  },
})

const getComponentClass = computed(() => {
  return `layout-${props.layout} fill-${props.fill}`
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsToast.scss */
</style>
