<template>
  <div
    class="psui-el-mini-tag"
    :class="getComponentClass"
    @click="emit('click', $event.target)"
  >
    <span>{{ message }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const emit = defineEmits(['click'])

const props = defineProps({
  layout: {
    type: String,
    default: 'info',
    validator: (value) => [
    'info',
    'success',
    'warning',
    'error',
    'default',
    'solid-info',
    'solid-success',
    'solid-warning',
    'solid-error',
    'solid-default'
    ].indexOf(value) !== -1,  
  },
  /**
   * It sets the message that will show up within the minitag when component is rendered.
   */
  message: {
    type: String,
    default: '',
  },
})

const getComponentClass = computed(() => {
  return `psui-el-mini-tag-layout-${props.layout}`
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsMiniTag.scss */
</style>
