<template>
  <button
    @click="onClick($event)"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    class="psui-el-button"
    :class="[getComponentClass, { hover: isHover }, { disabled: disabled || loading }]"
  >
    <svg
      v-if="loading"
      class="psui-animate-spin psui-mr-3 psui-h-5 psui-w-5 psui-text-black"
      :class="`${iconPosition == 'left' ? 'psui-mr-3' : 'psui-ml-3 psui--mr-1'}`"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="psui-opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      />
      <path
        class="psui-opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    <i
      v-else-if="icon"
      :class="iconClass"
      class="material-icons-round"
    >{{ icon }}</i>
    <span v-if="label">{{ label }}</span>
  </button>
</template>

<script setup>
import { computed, ref, } from 'vue'

const isHover = ref(false)

const emit = defineEmits(['click'])

const props = defineProps({
  label: {
    type: String,
    default: '',
  },
  layout: {
    type: String,
    default: 'solid',
    validator: (value) => ['solid', 'outline', 'ghost', 'onlytext', 'caution'].includes(value),
  },
  icon: {
    type: String,
    default: '',
  },
  iconPosition: {
    type: String,
    default: 'left',
    validator: (value) => ['left', 'right'].includes(value),
  },
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['small', 'medium', 'big'].includes(value),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  iconClass: {
    type: String,
    default: '',
  },
})

const getComponentClass = computed(() => {
  if (props.icon) {
    return `layout-${props.layout} size-${props.size} icon-${props.iconPosition}`
  } else {
    return `layout-${props.layout} size-${props.size}`
  }
})

const onClick = (event) => {
  if (props.disabled) return
  emit('click', event)
}

const onMouseEnter = () => {
  if (!props.disabled) {
    isHover.value = true
  }
}

const onMouseLeave = () => {
  if (!props.disabled) {
    isHover.value = false
  }
}
</script>
