<template>
  <div
    class="psui-el-input-select"
    :class="[getComponentClass, { selected: selectedClass }, { disabled: disabled }]"
  >
    <label
      :for="label"
      v-if="label"
    >{{ label }}</label>
    <div class="psui-el-input-select-wrapper">
      <select
        :name="label"
        :id="label"
        v-model="childValue"
      >
        <option
          value=""
          disabled
        >
          Select
        </option>
        <option
          v-for="item in getItems"
          :value="item[keyValue]"
          :key="item[keyValue]"
        >
          {{ item[keyLabel] }}
        </option>
      </select>
    </div>
    <p
      v-if="optionalLabel && !disabled && layout !== 'mini'"
      class="psui-el-input-helper"
    >
      {{ optionalLabel }}
    </p>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  /**
   * It sets the items which will be rendered within the select input.
   */
  items: {
    required: true,
  },
  value: {
    default: '',
    required: true,
  },
  /**
   * It sets the value label of the select input if needed.
   */
  label: {
    default: '',
  },
  /**
   * It sets a o optional label below the select input.
   */
  optionalLabel: {
    default: '',
  },
  /**
   * It sets the key label of your items if needed.
   */
  keyLabel: {
    default: 'label',
  },
  /**
   * It sets the key value of yout items if needed.
   */
  keyValue: {
    default: 'value',
  },
  /**
   * It disables the select input. All mouse events are disabled.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the layout of the select input. eg: default and mini.
   */
  layout: {
    type: String,
    default: 'default',
    validator: (value) => ['default', 'mini'].includes(value),
  },
})

const emit = defineEmits('input', 'change')

const selectedClass = ref(false)

const getComponentClass = computed(() => {
  return `layout-${props.layout}`
})

const childValue = computed({
  get() {
    return props.value
  },
  set(value) {
    if (isString.value) return emit('input', value)
    const finded = find(props.items, { [props.keyValue]: value })
    emit('input', value)
    emit('change', finded)
    selectedClass.value = true
  },
})

const isString = computed(() => {
  return props.items.length > 0 && typeof props.items[0] === 'string'
})

const getItems = computed(() => {
  if (props.items.length > 0 && typeof props.items[0] === 'string') {
    return props.items.map((item) => {
      return {
        [props.keyLabel]: item,
        [props.keyValue]: item,
      }
    })
  } else {
    return props.items
  }
})
</script>
