<template>
  <div
    class="psui-el-collapse"
    :class="{ 'status-opened': isOpen, 'disabled': disabled }"
  >
    <div
      class="psui-el-collapse-item-header"
      @click="toggle"
    >
      <i
        class="psui-el-collapse-item-icon psui-icon"
        :class="{'psui-rotate-90':isOpen}"
      >chevron_right</i>
      <slot
        name="header"
        :is-open="isOpen"
      >
        <div
          class="psui-el-collapse-item-header-wrapper"
          
          v-if="!hasCustomHeader"
        >
          <span
            v-if="title"
            class="psui-el-collapse-item-title"
            :class="[
              { 'disabled': disabled },                 
            ]"
          >
            {{ title }}
          </span>
          <div class="psui-el-collapse-item-header-action">
            <slot name="header-action" />  
          </div>
        </div>
      </slot>
    </div>
    <transition
      name="collapse-fade"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <div
        v-if="isOpen"
        class="psui-el-collapse-item-content"
      >
        <slot name="content" />
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const localOpened = ref(null)

const props = defineProps({
  /**
   * It sets the title of the accordion item.
   */
  title: {
    type: String,
    default: '',
  },
  /**
   * It sets the status of the accordion item when mounted.
   */
  opened: {
    type: [Boolean],
    default: false,
  },
  /**
   * It sets the text key to get the svg icon in Google Fonts. Make sure to get the correct description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: '',
  },
  /**
   * It sets if it has a custom header.
   */
  hasCustomHeader: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the disabled status.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
})

const isOpen = computed(() => {
  return localOpened.value !== null ? localOpened.value : props.opened
})

const toggle = () => {
  if (localOpened.value === null) {
    localOpened.value = !props.opened
  } else {
    localOpened.value = !localOpened.value
  }
}
const start = (el) => {
  el.style.height = el.scrollHeight + 'px'
  el.style.padding = el.srcollPaddingBottom + 'px'
}
const end = (el) => {
  el.style.height = ''
  el.style.padding = ''
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsCollapse.scss */
</style>



