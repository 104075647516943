<template>
  <div class="psui-el-date-card">
    <span class="psui-el-date-card-month-day">
      {{ getMonthAndDayFromDate }}
    </span>
    <span class="psui-el-date-card-year">
      {{ getYearFromDate }}
    </span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  date: {
    type: [Date, String],
    default: new Date(),
  },
})

const getMonthAndDayFromDate = computed(() => {
  const options = { month: 'short', day: '2-digit' }
  return new Date(props.date)?.toLocaleString('default', options).replace('.', '')
})

const getYearFromDate = computed(() => {
  return new Date(props.date)?.toLocaleString('default', { year: 'numeric' })
})
</script>
