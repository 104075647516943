<template>
  <div
    class="psui-el-card-infos"
    @click="onClick()"
  >
    <h5 class="psui-el-card-infos-title">
      <span class="psui-text-gray-60 psui-mr-1">{{ title }}</span>{{ subtitle }}
    </h5>
    <div class="psui-el-card-infos-content">
      <span class="psui-el-card-infos-icon material-icons-round">{{ icon }}</span>
      <h5 class="psui-text-gray-80">
        {{ total }}
      </h5>
    </div>
  </div>
</template>

<script setup>

const emit = defineEmits(['click'])

defineProps({
  title: {
    type: String,
    default: 'Total',
  },
  /**
   * It sets the subtitle of your card if needed.
   */
  subtitle: {
    type: String,
    default: '',
  },
  /**
   * It sets the text key to get the svg icon in Google Fonts. Make sure to get the right description of your icon on https://fonts.google.com/.
   */
  icon: {
    type: String,
    default: 'edit',
  },
  /**
   * It sets the data that will show up when component is mounted.
   */
  total: {
    type: String,
    default: '',
  },
})

const onClick = () => {
  emit('click')
}
</script>

<style>
/* Please, use the file src/assets/scss/components/PsCardInfos.scss */
</style>
