<template>
  <div
    class="psui-el-switch"
    :class="[getComponentClass, { disabled: disabled }]"
    @click="change()"
  >
    <input
      type="checkbox"
      :checked="value"
    >
    <label
      class="psui-el-switch-button"
      :class="[getToggleClass]"
    />
  </div>
</template>

<script setup>
//FIGMA CONTROLS & SELECTORS https://www.figma.com/file/Tto8hrNlSfuPcwd1pfqogF/%E2%9A%A1%EF%B8%8F-Design-System?node-id=1768%3A64852
import { computed } from 'vue'

const emit = defineEmits('change', 'update:value')

const props = defineProps({
  /**
   * It sets the label of the switch button.
   */
  label: {
    type: String,
    default: '',
  },
  /**
   * It sets the value of the switch button.
   */
  value: {
    type: Boolean,
  },
  backgroundColor: {
    type: String,
    default: 'psui-bg-green-20',
  },
  /**
   * It sets disabling mode.
   */
  disabled: {
    type: Boolean,
    default: false,
  },
  /**
   * It sets the size of the switch button. eg: small and big.
   */
  size: {
    type: String,
    default: 'big',
    validator: (value) => ['small', 'big'].includes(value),
  },
})

const getToggleClass = computed(() => {
  if (props.disabled) return 'toggle-false'
  return props.value ? `toggle-true ${props.backgroundColor}` : 'toggle-false psui-bg-gray-40'
})

const getComponentClass = computed(() => {
  return `size-${props.size}`
})

const change = () => {
  emit('update:value', !props.value)
  emit('change', !props.value)
}
</script>
