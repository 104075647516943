<template>
  <div
    class="psui-el-tag-scope"
    :class="getComponentClass"
  >
    <span class="psui-el-tag-scope-icon">{{ getComponentIcon }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({})

const getComponentClass = computed(() => {
  return `psui-el-tag-scope-${props.included}`
})

const getComponentIcon = computed(() => {
  return props.included ? 'check' : 'remove'
})
</script>

<style>
/* Please, use the file src/assets/scss/components/PsTagScope.scss */
</style>
